import React from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import update from 'immutability-helper';
import omit from 'lodash/omit';
import moment from 'moment';
import { AttendanceComments, Events, notifyError } from 'api';
import { Loading } from 'components';
import { AttendanceRecordDictionary } from '../../../helpers/dictionaries';
import AttendanceStatusFormatter from './AttendanceStatusFormatter';
import PercentCompleteFormatter from './PercentCompleteFormatter';
import AttendeeFormatter from './AttendeeFormatter';
import CommentFormatter from './CommentFormatter';
import StatusClickEditor from './StatusClickEditor';
import { failedAssertion } from 'helpers/reporting';
import { processDuplicateLogs } from './utils';

const { PRESENT, LATE, ABSENT } = AttendanceRecordDictionary.ATTENDANCE_STATUS;
const AttendanceStatusEditor = <StatusClickEditor />;

const validItems = (items) => {
  const hasInvalid = Object.values(items).some((item) => {
    if (![PRESENT, LATE, ABSENT].includes(item)) {
      failedAssertion('Attempted to save invalid attendance type', { type: item });
      return true;
    }
  });
  return !hasInvalid;
};

const computeAttendance = (rows) => {
  let row = {};
  for (row in rows) {
    const statusData = omit(rows[row], [
      'id',
      'name',
      'percent',
      'eventPk',
      'attendeePk',
      'comment',
    ]);
    const attended = Object.values(statusData).reduce((total, status) => {
      if (status === PRESENT || status === LATE) {
        total += 1;
      }
      return total;
    }, 0);
    const percent = ((attended / Object.keys(statusData).length) * 100).toFixed(1);
    rows[row] = Object.assign({}, rows[row], { percent: percent });
  }
  return rows;
};

const sortLogsByDate = (data) => {
  return data.sort((a, b) => {
    const aDate = moment(a.name);
    const bDate = moment(b.name);
    return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
  });
};

const columns = [
  { key: 'id', name: 'ID', hidden: true, width: 1 },
  {
    key: 'name',
    name: 'Attendee',
    editable: false,
    locked: true,
    width: 200,
    resizable: true,
    formatter: AttendeeFormatter,
  },
  {
    // Placeholder for comment column.
    // This gets overridden below - see stateFromApiResponse INSIDE the class
  },
  {
    key: 'percent',
    name: '% Attended',
    editable: false,
    width: 100,
    resizable: true,
    formatter: PercentCompleteFormatter,
  },
];

const stateFromApiResponse = (data, comments, grid) => {
  data = processDuplicateLogs(data);
  const rows = {};
  const logs = [];
  data.map((col) => {
    logs.push({
      key: `${col.pk}`,
      name: col.dupe ? `${col.date} (${col.dupe})` : col.date,
      editable: true,
      editor: AttendanceStatusEditor,
      formatter: AttendanceStatusFormatter,
      width: col.dupe ? 115 : 100,
      events: {
        onClick: (ev, args) => {
          const idx = args.idx;
          const rowIdx = args.rowIdx;
          grid().openCellEditor(rowIdx, idx);
        },
      },
    });
    col.records.map((row) => {
      const existing = rows[row.attendee];
      if (existing) {
        existing[col.pk] = row.status;
        rows[row.attendee] = existing;
      } else {
        const comment = comments.find((comment) => comment.attendee === row.attendee);
        rows[row.attendee] = {
          id: row.attendee,
          name: { name: row.name, slug: row.slug },
          eventPk: col.event,
          attendeePk: row.attendee,
          comment: comment || {},
          [col.pk]: row.status,
        };
      }
    });
  });
  const rowsCopy = Object.assign({}, rows);
  let attendees = Object.values(computeAttendance(rowsCopy));
  const _columns = columns.concat(sortLogsByDate(logs));

  // sort attendees by first name
  attendees.map((row, i) => {
    if (row.name.name.includes(',')) {
      const names = row.name.name.split(',');
      [names[0], names[1]] = [names[1], names[0]];
      attendees[i].name = { name: names.join(' '), slug: row.name.slug };
    }
  });
  attendees.sort((a, b) => a.name.name.localeCompare(b.name.name));

  const _rows = attendees;
  return { _columns, _rows };
};

export default class AttendanceGrid extends React.Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
    attendanceLogAdded: PropTypes.object.isRequired,
    attendanceLogDeleted: PropTypes.number.isRequired,
  };

  state = {
    isLoading: true,
    apiData: [],
    comments: [],
    _columns: [],
    _rows: [],
  };

  componentDidMount() {
    this.updateFromApi();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.eventId !== this.props.eventId ||
      nextProps.attendanceLogAdded.pk !== this.props.attendanceLogAdded.pk
    ) {
      const updatedLogs = this.state.apiData.concat(nextProps.attendanceLogAdded);
      this.setState({
        apiData: updatedLogs,
        ...this.stateFromApiResponse(updatedLogs, this.state.comments),
      });
    }
    if (nextProps.attendanceLogDeleted !== this.props.attendanceLogDeleted) {
      const updatedLogs = this.state.apiData.filter(
        (data) => data.pk !== nextProps.attendanceLogDeleted
      );
      this.setState({
        apiData: updatedLogs,
        ...this.stateFromApiResponse(updatedLogs, this.state.comments),
      });
    }
  }

  stateFromApiResponse(data, comments) {
    // We have to manually add the comments column because it
    // requires a prop that references the class instance.
    const commentsColumn = {
      key: 'comment',
      name: '',
      editable: false,
      locked: true,
      width: 50,
      resizable: true,
      formatter: <CommentFormatter onFormSubmit={this.handleCommentSubmit} />,
      getRowMetaData: (row) => row,
    };

    const state = stateFromApiResponse(data, comments, () => this.grid);
    state._columns.splice(2, 1, commentsColumn);
    return state;
  }

  updateFromApi() {
    Events.getAttendanceList(this.props.eventId).then((apiData) => {
      this.setState({
        isLoading: false,
        apiData,
        ...this.stateFromApiResponse(apiData, this.state.comments),
      });
    });
    AttendanceComments.get(this.props.eventId).then((comments) => {
      this.setState({
        comments,
        ...this.stateFromApiResponse(this.state.apiData, comments),
      });
    });
  }

  handleCommentSubmit = (comment) => {
    const newComments = [...this.state.comments];
    const existingComment = newComments.find((c) => c.pk === comment.pk);
    if (existingComment) {
      Object.assign(existingComment, comment);
    } else {
      newComments.push(comment);
    }
    this.setState({
      comments: newComments,
      ...this.stateFromApiResponse(this.state.apiData, newComments),
    });
  };

  rowGetter = (i) => this.state._rows[i];

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    if (!validItems(updated)) {
      window.show_stack_topleft('Error', 'Failed to save change, please try again later.', 'error');
      return;
    }
    const [logId, status] = Object.entries(updated)[0].map((item, idx) =>
      idx === 0 ? parseInt(item) : item
    );
    let attendeeId;
    let { _rows } = this.state;
    for (let i = fromRow; i <= toRow; i++) {
      let rowToUpdate = _rows[i];
      let previousStatus = rowToUpdate[logId];
      let updatedRow = update(rowToUpdate, { $merge: updated });
      _rows[i] = updatedRow;
      attendeeId = updatedRow.id;
      if (!previousStatus) {
        Events.postAttendanceRecord(this.props.eventId, logId, {
          attendee: attendeeId,
          status,
        }).catch((error) => {
          // revert state change
          _rows[i] = rowToUpdate;
          this.setState({ _rows });
          notifyError(error);
        });
      } else {
        Events.patchAttendanceRecord(this.props.eventId, logId, attendeeId, {
          status,
        }).catch((error) => {
          // revert state change
          _rows[i] = rowToUpdate;
          this.setState({ _rows });
          notifyError(error);
        });
      }
    }
    let { apiData } = this.state;
    const updatedData = apiData.map((log) => {
      if (log.pk !== logId) {
        return log;
      }
      const records = log.records.map((record) =>
        record.attendee === attendeeId ? { ...record, status } : record
      );
      return { ...log, records };
    });
    _rows = computeAttendance(_rows);
    this.setState({ _rows: _rows, apiData: updatedData });
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <ReactDataGrid
        ref={(node) => {
          this.grid = node;
        }}
        enableCellSelect
        columns={this.state._columns}
        rowGetter={this.rowGetter}
        rowsCount={this.state._rows.length}
        onGridRowsUpdated={this.handleGridRowsUpdated}
        minHeight={450} // 450px is a good size for both desktop & mobile
      />
    );
  }
}
