import React from 'react';
import PropTypes from 'prop-types';
import { AttendanceRecordDictionary } from '../../../helpers/dictionaries';

const { PRESENT, LATE, ABSENT } = AttendanceRecordDictionary.ATTENDANCE_STATUS;

export default class StatusClickEditor extends React.Component {
  state = {
    value: this.props.value,
  };

  static propTypes = {
    value: PropTypes.string,
    column: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  };

  getValue = () => {
    return {
      [this.props.column.key]: this.state.value,
    };
  };

  getStyle = () => {
    return {
      width: '100%',
    };
  };

  getInputNode = () => {
    return this.refs.StatusWrapper;
  };

  inheritContainerStyles = () => {
    return true;
  };

  handleClick = (e) => {
    let { value } = this.state;
    if (value === PRESENT) {
      value = LATE;
    } else if (value === LATE) {
      value = ABSENT;
    } else if (value === ABSENT) {
      value = PRESENT;
    } else if (value === '' || value === null) {
      value = PRESENT;
    }
    this.setState({ value: value });
  };

  render() {
    if (this.state.value === PRESENT) {
      return (
        <button
          className="btn btn-block"
          style={{ backgroundColor: '#dff0d8' }}
          onClick={this.handleClick}
        >
          <i className="fa fa-lg fa-check-circle icon-space-l icon-space-r" />
        </button>
      );
    }
    if (this.state.value === LATE) {
      return (
        <button
          className="btn btn-block"
          style={{ backgroundColor: '#fcf8e3' }}
          onClick={this.handleClick}
        >
          <i className="fa fa-lg fa-clock-o icon-space-l icon-space-r" />
        </button>
      );
    }
    if (this.state.value === ABSENT) {
      return (
        <button
          className="btn btn-block"
          style={{ backgroundColor: '#f2dede' }}
          onClick={this.handleClick}
        >
          <i className="fa fa-lg fa-minus-circle icon-space-l icon-space-r" />
        </button>
      );
    }
    return (
      <button className="btn btn-block" onClick={this.handleClick}>
        <i className="fa fa-lg fa-question-circle icon-space-l icon-space-r" />
      </button>
    );
  }
}
