import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PercentCompleteFormatter = (props) => {
  const percentComplete = props.value + '%';
  const percent = parseFloat(props.value);
  const progressBarType = percent >= 90 ? 1 : percent >= 80 ? 0 : -1;
  const className = classNames('progress-bar', {
    'progress-bar-success': progressBarType === 1,
    'progress-bar-warning': progressBarType === 0,
    'progress-bar-danger': progressBarType === -1,
  });
  return (
    <div className="progress" style={{ marginTop: '20px' }}>
      <div
        className={className}
        role="progressbar"
        aria-valuenow="{percentComplete}"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: percentComplete }}
      >
        {percentComplete}
        <span className="sr-only">{percentComplete}%</span>
      </div>
    </div>
  );
};
PercentCompleteFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PercentCompleteFormatter;
