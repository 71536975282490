import React from 'react';
import PropTypes from 'prop-types';
import { AttendanceRecordDictionary } from '../../../helpers/dictionaries';
const { PRESENT, LATE, ABSENT } = AttendanceRecordDictionary.ATTENDANCE_STATUS;

class AttendanceStatusFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.oneOf([PRESENT, LATE, ABSENT, '', null]).isRequired,
    onClick: PropTypes.func,
  };

  render() {
    switch (this.props.value) {
      case PRESENT:
        return (
          <div
            ref="StatusWrapper"
            className="center"
            style={{ backgroundColor: '#dff0d8' }}
            onClick={this.props.onClick}
          >
            <i className="fa fa-check-circle icon-space-l icon-space-r" />
          </div>
        );
      case LATE:
        return (
          <div
            ref="StatusWrapper"
            className="center"
            style={{ backgroundColor: '#fcf8e3' }}
            onClick={this.props.onClick}
          >
            <i className="fa fa-clock-o icon-space-l icon-space-r" />
          </div>
        );
      case ABSENT:
        return (
          <div
            ref="StatusWrapper"
            className="center"
            style={{ backgroundColor: '#f2dede' }}
            onClick={this.props.onClick}
          >
            <i className="fa fa-minus-circle icon-space-l icon-space-r" />
          </div>
        );
      default:
        return (
          <div ref="StatusWrapper" className="center" onClick={this.props.onClick}>
            <i className="fa fa-question-circle icon-space-l icon-space-r" />
          </div>
        );
    }
  }
}

export default AttendanceStatusFormatter;
