import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Events } from 'api';
import { Loading } from 'components';
import { processDuplicateLogs } from './utils';

export default class DeleteAttendanceLogForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
  };

  state = {
    logs: [],
    selectedLog: null,
    isLoading: true,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.selectedLog) {
      return;
    }
    this.props.onFormSubmit(this.state.selectedLog);
  };

  handleSelectChanged = (e) => {
    this.setState({ selectedLog: e.target.value });
  };

  componentDidMount() {
    Events.getAttendanceList(this.props.eventId).then((logs) => {
      const selectedLog = logs.length > 0 ? logs[0].pk : null;
      this.setState({ isLoading: false, logs: processDuplicateLogs(logs), selectedLog });
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <select className="form-control" onChange={this.handleSelectChanged}>
            {this.state.logs.map((log) => (
              <option key={log.pk} value={log.pk}>
                {moment(log.date).format('YYYY-MM-DD')} Log
                {log.dupe ? ` (${log.dupe})` : ''}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary" disabled={!this.state.selectedLog}>
          Delete
        </button>
      </form>
    );
  }
}
