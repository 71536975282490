import React from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { AttendanceRecordDictionary } from 'helpers/dictionaries';
import { FormRadioGroup } from 'components';

const { ATTENDANCE_STATUS } = AttendanceRecordDictionary;
const statusOptions = [
  [ATTENDANCE_STATUS.PRESENT, 'Present'],
  [ATTENDANCE_STATUS.ABSENT, 'Absent'],
];

export default class CreateAttendanceLogForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
  };

  state = {
    date: null,
    status: ATTENDANCE_STATUS.PRESENT,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { date, status } = this.state;
    if (!date || !status) {
      return;
    }
    this.props.onFormSubmit({ date: date.format('YYYY-MM-DD'), status });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <SingleDatePicker
            date={this.state.date}
            onDateChange={(date) => this.setState({ date })}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            isOutsideRange={() => false}
          />
        </div>
        <FormRadioGroup
          name="status"
          label={{ text: 'Default Status', htmlFor: 'status' }}
          value={this.state.status}
          options={statusOptions}
          onChange={(e) => this.setState({ status: e.target.value })}
        />
        <button type="submit" className="btn btn-primary" disabled={!this.state.date}>
          Submit
        </button>
      </form>
    );
  }
}
