import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import { AttendanceComments, notifyError, notifySuccess } from 'api';

export default class CommentModal extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    commentPk: PropTypes.number,
    name: PropTypes.string.isRequired,
    eventPk: PropTypes.number.isRequired,
    attendeePk: PropTypes.number.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  };

  state = {
    value: this.props.value,
    loading: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalOpen && !this.props.modalOpen) {
      this.setState({ value: nextProps.value, loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen && !prevProps.modalOpen && this.refs.textarea) {
      this.refs.textarea.focus();
    }
  }

  handleChange = (event) => this.setState({ value: event.target.value });

  handleSubmit = (e) => {
    e.preventDefault();
    this.updateComment(this.state.value);
  };

  updateComment(comment) {
    this.setState({ loading: true });

    let apiFunc;
    if (this.props.commentPk) {
      apiFunc = AttendanceComments.patch(this.props.commentPk, { comment });
    } else {
      apiFunc = AttendanceComments.post({
        comment,
        event: this.props.eventPk,
        attendee: this.props.attendeePk,
      });
    }

    apiFunc
      .then((response) => {
        this.props.onFormSubmit(response);
        this.props.onModalClose();
        notifySuccess('Attendance comment saved.');
      })
      .catch((error) => {
        this.setState({ loading: false });
        notifyError(error);
      });
  }

  render() {
    const iconClasses = !this.state.loading
      ? 'fa fa-check icon-space-r'
      : 'fa fa-spin fa-circle-o-notch icon-space-r';
    return (
      <Modal
        show={this.props.modalOpen}
        onHide={this.props.onModalClose}
        modalPrefix="bootstrap-modal modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments for {this.props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <textarea
                ref="textarea"
                value={this.state.value}
                onChange={this.handleChange}
                className="form-control"
                rows={3}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Dismiss className="btn btn-default">Cancel</Modal.Dismiss>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.handleSubmit}
            disabled={this.state.loading}
          >
            <i className={iconClasses} />
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
