import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactRouterPropTypes from 'react-router-prop-types';

import { requestEventsDetail } from 'store/actions/events/detail';
import { eventExplorePropTypes } from 'helpers/proptypes';
import { Alert, Loading, InfoModal, PageHeader } from 'components';
import { AttendanceLegend } from 'components/shared';

import AttendanceTracker from './AttendanceTracker';

class AttendanceTrackerPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    event: eventExplorePropTypes,
    requestEventsDetail: PropTypes.func.isRequired,
  };

  state = {
    modalOpen: false,
  };

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  componentDidMount() {
    const { eventId } = this.props.match.params;
    this.props.requestEventsDetail(eventId);
  }

  render() {
    const { isLoading, hasErrored, event } = this.props;
    if (hasErrored) {
      return (
        <div className="col-xs-12">
          <Alert type="danger" icon="fa-exclamation-triangle">
            An error occurred.
          </Alert>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="col-xs-12">
          <Loading />
        </div>
      );
    }

    const { eventId } = this.props.match.params;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="white-soft-box2 white-soft-box2--no-animate"
              style={{ paddingBottom: '16px' }}
            >
              <PageHeader.Basic
                title="Attendance Tracker"
                subtitle={event.title}
                actions={
                  <button className="btn btn-info pull-right" onClick={this.openModal}>
                    <i className="fa fa-question-circle icon-space-r" />
                    Help
                  </button>
                }
              />
              <div className="row">
                <div className="col-md-7">
                  <h3>Facilitator(s): {event.facilitator_names}</h3>
                  <h3>
                    <Link to={window.URLS['pd:facilitator_wrap_up_detail'](eventId)}>
                      Event Wrap Up
                    </Link>
                  </h3>
                </div>
                <div className="col-md-5">
                  <AttendanceLegend tableBodyStyles={{ backgroundColor: '#fff' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AttendanceTracker eventId={eventId} />
        <ReactTooltip type="custom" effect="solid" className="events__tooltip" multiline />
        <InfoModal
          modalOpen={this.state.modalOpen}
          onModalClose={this.closeModal}
          modalTitle="How to use the Attendance Tracker"
        >
          <React.Fragment>
            <p>The attendance tracker is an excel-like spreadsheet of attendance.</p>
            <p className="bold">Viewing</p>
            <ul>
              <li>Scroll down inside the grid to see more rows.</li>
            </ul>
            <p className="bold">Adding</p>
            <ol>
              <li>Click the Add Attendance Log button.</li>
              <li>Choose the date for the log entry.</li>
              <li>Hit Submit.</li>
            </ol>
            <p className="bold">Editing</p>
            <ol>
              <li>
                To edit a cell click on it once to open edit mode, you will notice the button
                expands.
              </li>
              <li>
                To modify the attendance status click on the cell again and it will cycle through
                the options.
              </li>
              <li>Hit enter or click on another cell to confirm the changes.</li>
              <li>Hit ESC to cancel the changes.</li>
            </ol>
            <p className="bold">Deleting</p>
            <ul>
              <li>
                Delete an attendance log by right clicking on a cell in the column you want to
                delete. A menu will open that will let you delete that column's attendance log.
              </li>
            </ul>
            <p>
              <span className="bold">Tips</span>
            </p>
            <ul>
              <li>Use keyboard arrow keys to navigate cells without opening edit mode.</li>
              <li>
                Use click and drag (like in Excel) to autofill up or down a column (Can't be in edit
                mode).
              </li>
            </ul>
          </React.Fragment>
        </InfoModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ eventsDetail }) => {
  return {
    isLoading: eventsDetail.isLoading,
    hasErrored: eventsDetail.hasErrored,
    event: eventsDetail.event,
  };
};

export default connect(
  mapStateToProps,
  { requestEventsDetail }
)(AttendanceTrackerPage);
