import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AttendeeFormatter = ({ value }) => (
  <Link to={window.URLS['accounts:public_profile_detail'](value.slug)}>{value.name}</Link>
);

AttendeeFormatter.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

export default AttendeeFormatter;
