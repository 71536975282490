import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import CreateAttendanceLogForm from './CreateAttendanceLogForm';

const CreateAttendanceLogModal = ({ modalOpen, onModalClose, onFormSubmit }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>Add Attendance Log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateAttendanceLogForm onFormSubmit={onFormSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

CreateAttendanceLogModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default CreateAttendanceLogModal;
