import React from 'react';
import PropTypes from 'prop-types';
import { AttendanceLog, Events, notifySuccess } from 'api';
import CreateAttendanceLogModal from './CreateAttendanceLogModal';
import DeleteAttendanceLogModal from './DeleteAttendanceLogModal';
import AttendanceGrid from './AttendanceGrid';

class AttendanceTracker extends React.Component {
  static propTypes = {
    eventId: PropTypes.string.isRequired,
  };

  state = {
    createModalOpen: false,
    deleteModalOpen: false,
    attendanceLogAdded: { pk: -1 },
    attendanceLogDeleted: -1,
  };

  handleCreateModalClose = () => {
    this.setState({ createModalOpen: false });
  };

  handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  };

  handleCreateAttendanceLogFormSubmit = ({ date, status }) => {
    this.setState({ createModalOpen: false });
    const postData = { event: this.props.eventId, date, status };
    Events.postAttendanceLog(this.props.eventId, postData).then((attendanceLogAdded) => {
      this.setState({ attendanceLogAdded });
    });
  };

  handleDeleteAttendanceLogFormSubmit = (logId) => {
    this.setState({ deleteModalOpen: false });

    AttendanceLog.delete(logId).then(() => {
      this.setState({ attendanceLogDeleted: parseInt(logId) });
      notifySuccess('Attendance log deleted');
    });
  };

  render() {
    return (
      <React.Fragment>
        <p>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => this.setState({ createModalOpen: !this.state.createModalOpen })}
          >
            <i className="fa fa-plus icon-space-r" />
            Add Attendance Log
          </button>
          <button
            className="btn btn-danger pull-right"
            type="button"
            onClick={() => this.setState({ deleteModalOpen: !this.state.deleteModalOpen })}
          >
            <i className="fa fa-trash icon-space-r" />
            Delete Attendance Log
          </button>
        </p>
        <AttendanceGrid
          eventId={this.props.eventId}
          attendanceLogAdded={this.state.attendanceLogAdded}
          attendanceLogDeleted={this.state.attendanceLogDeleted}
        />
        <CreateAttendanceLogModal
          onFormSubmit={this.handleCreateAttendanceLogFormSubmit}
          modalOpen={this.state.createModalOpen}
          onModalClose={this.handleCreateModalClose}
        />
        <DeleteAttendanceLogModal
          onFormSubmit={this.handleDeleteAttendanceLogFormSubmit}
          modalOpen={this.state.deleteModalOpen}
          onModalClose={this.handleDeleteModalClose}
          eventId={this.props.eventId}
        />
      </React.Fragment>
    );
  }
}

export default AttendanceTracker;
