export const processDuplicateLogs = (logs) => {
  const logMap = {};
  return logs.map((log) => {
    if (logMap[log.date]) {
      logMap[log.date]++;
      return { ...log, dupe: logMap[log.date] };
    } else {
      logMap[log.date] = 1;
      return log;
    }
  });
};
