import React from 'react';
import PropTypes from 'prop-types';
import CommentModal from './CommentModal';

class CommentFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.shape({
      pk: PropTypes.number,
      comment: PropTypes.string,
    }),
    dependentValues: PropTypes.shape({
      name: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
      eventPk: PropTypes.number.isRequired,
      attendeePk: PropTypes.number.isRequired,
    }),
    onFormSubmit: PropTypes.func,
  };

  state = {
    modalOpen: false,
  };

  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  render() {
    const iconName = this.props.value.comment ? 'commenting' : 'comment-o';

    const { name, eventPk, attendeePk } = this.props.dependentValues;

    return (
      <div>
        <div className="center" onClick={this.openModal}>
          <i className={`fa fa-${iconName} icon-space-l icon-space-r cursor-pointer`} />
        </div>
        <CommentModal
          name={name.name}
          eventPk={eventPk}
          attendeePk={attendeePk}
          value={this.props.value.comment}
          commentPk={this.props.value.pk}
          modalOpen={this.state.modalOpen}
          onModalClose={this.closeModal}
          onFormSubmit={this.props.onFormSubmit}
        />
      </div>
    );
  }
}

export default CommentFormatter;
