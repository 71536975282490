import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';
import DeleteAttendanceLogForm from './DeleteAttendanceLogForm';

const DeleteAttendanceLogModal = ({ modalOpen, onModalClose, onFormSubmit, eventId }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>Delete Attendance Log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DeleteAttendanceLogForm onFormSubmit={onFormSubmit} eventId={eventId} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

DeleteAttendanceLogModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default DeleteAttendanceLogModal;
